
export const articles = [
  {
  slug: 'bio',
  title: 'Bio',
  subtitle: '',
  date: '',
  authors: '',
  body: [{
    type: 'text',
    content: '',
  }],
  }
]
