import FredImage from '../images/people/fred.jpeg'
import MiaImage from '../images/people/mia.jpeg'
import BeccaImage from '../images/people/becca.jpeg'
import ZhivarImage from '../images/people/zhivar.png'
import AbelImage from '../images/people/abel.jpeg'
import ZhuoyuImage from '../images/people/zhuoyu.jpeg'
import HuyImage from '../images/people/huy.jpeg'

import TBDImage from '../images/people/tbd.png'

export const faculty = [{
  name: 'Fred Morstatter',
  image: FredImage,
  link: 'https://www.isi.edu/directory/fredmors/',
}]


// export const researchers = [{
//   name: 'Goran Murić',
//   image: 'http://gmuric.com/images/me.jpg',
//   link: 'http://gmuric.com/',
// }, {
//   name: 'Marcin Abram',
//   image: 'https://www.isi.edu/sites/default/files/photos/users/picture-452.jpg',
//   link: 'https://www.isi.edu/people/mjabram/about',
// }]


var _students = [{
  name: 'Yuzhong Huang',
  image: 'http://www.yuzhonghuang.org/images/bio-photo.jpg',
  link: 'http://www.yuzhonghuang.org/',
  tagline: 'Forecasting, 3D Scene Reconstruction'
}, {
  name: 'Myrl Marmarelis',
  image: 'https://www.isi.edu/wp-content/uploads/headshots/myrlm-1.jpg',
  link: 'https://myrl.marmarel.is/',
  tagline: 'Causal Inference'
}, {
  name: 'Bahare Harandi',
  image: 'https://www.isi.edu/wp-content/uploads/headshots/harandiz-1.jpg',
  link: 'https://www.isi.edu/directory/harandiz/',
  tagline: 'Characterizing LLM Harms'
}, {
  name: 'Abel Salinas',
  image: AbelImage,
  link: 'https://www.isi.edu/directory/asalinas/',
  tagline: 'Identifying Implicit Biases in LLMs'
}, {
  name: 'Zhivar Sourati',
  image: ZhivarImage,
  link: 'https://zhpinkman.github.io/',
  tagline: 'Analogical Reasoning, LLM Fidelity'
}, {
  name: 'Becca Dorn',
  image: BeccaImage,
  link: 'https://www.isi.edu/directory/rdorn/',
  tagline: 'Toxicity Detection Against Marginalized Groups'
}, {
  name: 'Zhuoyu Shi',
  image: ZhuoyuImage,
  link: '#',
  tagline: 'Computational Social Science, Social Networks'
}, {
  name: 'James Huang',
  image: TBDImage,
  link: '#',
  tagline: 'Natural Language Processing'
}, {
  name: 'Mia Sultan',
  image: MiaImage,
  link: 'https://www.isi.edu/directory/osultan/',
  tagline: 'Persuasion in Forecasting Platforms'
}, ];
_students.sort((a, b) => a['name'].localeCompare(b['name']));
export const students = _students;


var _alumni = [{
  name: 'Ninareh Mehrabi',
  image: 'https://www.isi.edu/wp-content/uploads/headshots/ninarehm-4.jpg',
  link: 'https://ninarehm.github.io/',
  tagline: 'PhD in CS (2022). Disseration Title: Responsible Artificial Intelligence for a Complex World'
}, {
  name: 'Huy Tran Nghiem',
  image: HuyImage,
  link: 'https://www.isi.edu/directory/ninarehm/',
  tagline: 'Masters in CS (2023). Hate Speech Detection'
}];
_alumni.sort((a, b) => a['name'].localeCompare(b['name']));
export const alumni = _alumni;