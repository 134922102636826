import SAGEImage from '../images/charts.jpg'
import VENICEImage from '../images/projects/venice.png'
import OPCINTImage from '../images/projects/opcint.png'
import UGBImage from '../images/networks.jpg'
import PODIUMImage from '../images/projects/podium.png'
import O2OImage from '../images/projects/o2o.png'
import PersuasionImage from '../images/projects/persausion.png'

export const projects = [{
  active: true,
  name: 'VENICE',
  slug: 'venice',
  image: VENICEImage,
  subtitle: 'Verifying Implicit Cultural Models',
  description: 'People regularly use their implicit understanding of the environment--their mental model--to navigate the world. These mental models can provide a meaningful signal to better predict how future events will unfold, if only we can tap into them. This project combines state-of-the-art computational tools with cutting edge survey techniques to identify mental models in remote regions of the world.',
  sponsor_line: "DARPA Habitus"
}, {
  active: true,
  name: 'OPCINT',
  slug: 'opcint',
  image: OPCINTImage,
  subtitle: 'Operational Collective Intelligence',
  description: 'Collective Intelligence emerges from connecting humans and machines to enable decision-advantage. Aggregative crowdsourced forecasting (ACF), elicits predictions and rationales from a diverse crowd, and then aggregates them to inform higher-level decision-making. ACF helps commercial companies and government organizations to overcome individual bias by collecting many predictions. In this project we explore the role of ACF in very large organizations, with a focus on leveraging LLMs to train and scale forecasters.',
  sponsor_line: "Office of Naval Research"
}, {
  active: true,
  name: 'O2O',
  slug: 'o2o',
  image: O2OImage,
  subtitle: 'Detecting Causal Pathways in Online-Offline Systems',
  description: 'Social instability is increasingly influenced by the interplay between online and offline realms, with complex, multiscale causal dynamics. This project aims to understand and predict these interactions, using diverse data to develop responses to online-offline spillovers, focusing on causal pathways, behavioral impacts, and the stability of these coupled systems.',
  sponsor_line: "Air Force Office of Scientific Research (MURI)"
}, {
  active: true,
  name: 'Persuasion',
  slug: 'persuasion',
  image: PersuasionImage,
  subtitle: 'Persuasion, Identity, and Morality in Social-Cyber Environments',
  description: 'This project aims to develop new theories and models to understand and counteract social-cyber-attacks using cognitive, moral, and social perspectives. The focus is on understanding social-cyber influence processes and identifying interventions to mitigate their impact and enhance resilience. The approach includes network analytics, machine learning, and computational modeling, applied to diverse social media data, to address challenges in persuasion, identity, morality, and emotion in the context of social cybersecurity.',
  sponsor_line: "Office of Naval Research (MURI)"
}, {
  active: true,
  name: 'PODIUM',
  slug: 'podium',
  image: PODIUMImage,
  subtitle: 'Public Response Overview using Automated Community Testbeds',
  description: 'PODIUM fosters positive and productive online discourse. It aims to counteract radicalization and polarization in digital spaces by utilizing empathetic intervention with generative language models. PODIUM employs conversational agents, trained to intervene in discussions, guiding them towards constructive engagement. The project focuses on automating mediation with contextually relevant, proactive strategies, aiming to preserve freedom of speech while mitigating harmful or divisive content. This approach combines network analytics, machine learning, and ethical reasoning.',
  sponsor_line: "DARPA Civil Santuaries"
}, {
  active: false,
  name: 'UGB',
  slug: 'ugb',
  image: UGBImage,
  subtitle: 'Understanding Group Biases',
  description: 'Ethnographies are resources generated by social scientists to understand the beliefs, values, and traditions of a culture. The problem is that they are labor intensive and difficult to build. The ultimate goal of Understanding Group Biases (UGB) is to do “big ethnography” using machine learning and NLP to automate the laborious task of anthropologists who spend years doing field research in order to study different cultures. UGB aims to detect cultural models using machine learning and natural language processing (NLP) tools applied to huge swaths of web data.',
  sponsor_line: "DARPA Understanding Group Biases"
}, {
  active: false,
  name: 'SAGE',
  slug: 'sage',
  image: SAGEImage,
  subtitle: 'Synergistic Anticipation of Geopolitical Events',
  description: 'SAGE is a hybrid forecasting platform that seeks to combine the unique talens of humans and machines to make more accurate predictions about the future. SAGE uses unique data tools and machine learning methods to “hybridize” the forecasting process.',
  sponsor_line: "IARPA Hybrid Forecasting Competition",
  relevant_publications: []
}]
