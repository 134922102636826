import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Paper, Typography, Grid } from '@material-ui/core'

import logo from '../images/isi_logo.png'
import fredsface from '../images/people/fred.jpeg'


const styles = theme => ({
  intro: {
    position: 'absolute',
    top: '15vh',
    left: '5vw',
    width: '90vw',
    padding: theme.spacing(3),
    '@media (min-width:1000px)': {
      padding: theme.spacing(4),
      width: '90vw',
      top: '30vh',
    },
    '@media (min-width:1200px)': {
      padding: theme.spacing(5),
      width: '80vw',
      top: '35vh',
    },
    '@media (min-width:1400px)': {
      padding: theme.spacing(5),
      width: '70vw',
      top: '35vh',
    },
    '@media (min-width:1600px)': {
      padding: theme.spacing(5),
      width: '60vw',
      top: '35vh',
    },
    '@media (min-width:1750px)': {
      padding: theme.spacing(5),
      width: '50vw',
      top: '35vh',
    },
  }
  
})


class Intro extends React.Component {
  
  render() {
    const {classes} = this.props
    return (
      <React.Fragment>
        <Paper elevation={3} className={classes.intro}>
        <Grid container spacing={1} justify="center">
          <Grid item xs={3}>
            <img src={fredsface} width='100%' alt='freds-mug'/>
          </Grid>
          <Grid item xs={7} >
            <Typography
              component="h3"
              variant="h3"
              className={classes.title} margin='0'>
              Fred Morstatter
              </Typography>
              <Typography>
              Research Assistant Professor, Thomas Lord Department of Computer Science, University of Southern California<br/>
              Principal Scientist, USC Information Sciences Institute<br/>
              Associate Director, <a href="https://datascience.usc.edu/" target='_blank'>USC Data Science</a>
            </Typography>
          </Grid>
          <Grid item xs={2} >
            <img src={logo} width='100%' alt='isi-logo' />
          </Grid>
        </Grid>
        </Paper>
      {/* <Paper elevation={3} className={classes.intro}>
      <img src={fredsface} className={classes.logo} alt="fred-mug" />
      <Typography
      component="h3"
      variant="h3"
      className={classes.title}>
      Fred Morstatter
      </Typography>
      <Typography
      component="p"
      variant="body1"
      className={classes.text}>
      Research Assistant Professor, Thomas Lord Department of Computer Science, University of Southern California<br/>
      Principal Scientist, USC Information Sciences Institute<br/>
      Associate Director, <a href="https://datascience.usc.edu/">USC Data Science</a>
      </Typography>
      
      <img src={logo} className={classes.logo} alt="isi-logo" />
    </Paper> */}
    </React.Fragment>
    )
  }
}


export default withStyles(styles)(Intro)
