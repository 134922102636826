export const publications = {

    'zhao2010advancing': `@article{zhao2010advancing,
      title={Advancing Feature Selection Research: ASU Feature Selection Repository},
      author={Zhao, Zheng and Morstatter, Fred and Sharma, Shashvata and Alelyani, Salem and Anand, Aneeth and Liu, Huan},
      journal={School of Computing, Informatics, and Decision Systems Engineering, Arizona State University, Tempe},
      year={2010}
    }
    `,
    
    'fung2011feature': `@article{fung2011feature,
      title={Feature selection strategy in text classification},
      author={Fung, Pui and Morstatter, Fred and Liu, Huan},
      journal={Advances in Knowledge Discovery and Data Mining},
      pages={26--37},
      year={2011},
      publisher={Springer}
    }
    `,
    
    'morstatter2012opening': `@article{morstatter2012opening,
      title={Opening doors to sharing social media data},
      author={Morstatter, Fred and Liu, Huan and Zeng, Daniel},
      journal={IEEE intelligent systems},
      volume={27},
      number={1},
      pages={47--51},
      year={2012},
      publisher={IEEE}
    }
    `,
    
    'kumar2012navigating': `@inproceedings{kumar2012navigating,
      title={Navigating information facets on Twitter (NIF-T)},
      author={Kumar, Shamanth and Morstatter, Fred and Marshall, Grant and Liu, Huan and Nambiar, Ullas},
      booktitle={Proceedings of the 18th ACM SIGKDD international conference on Knowledge discovery and data mining},
      pages={1548--1551},
      year={2012}
    }
    `,
    
    'kumar2013whom': `@inproceedings{kumar2013whom,
      title={Whom Should I Follow?: Identifying Relevant Users During Crises.},
      author={Kumar, Shamanth and Morstatter, Fred and Zafarani, Reza and Liu, Huan},
      booktitle={Hypertext},
      pages={139--147},
      year={2013}
    }
    `,
    
    'morstatter2013sample': `@inproceedings{morstatter2013sample,
      title={Is the Sample Good Enough? Comparing Data from Twitter’s Streaming API with Twitter’s Firehose},
      author={Morstatter, Fred and Pfeffer, Jürgen and Liu, Huan and Carley, Kathleen M},
      booktitle={International Conference on Weblogs and Social Media},
      pages={400--408},
      year={2013},
      organization={AAAI}
    }
    `,
    
    'morstatter2013understanding': `@inproceedings{morstatter2013understanding,
      title={Understanding Twitter Data with TweetXplorer},
      author={Morstatter, Fred and Kumar, Shamanth and Liu, Huan and Maciejewski, Ross},
      booktitle={19th ACM SIGKDD Conference on Knowledge Discovery and Data Mining},
      pages={1482--1485},
      year={2013},
      organization={ACM}
    }
    `,
    
    'carley2013near': `@inproceedings{carley2013near,
      title={Near Real Time Assessment of Social Media Using Geo-Temporal Network Analytics},
      author={Carley, Kathleen M and Pfeffer, Jürgen and Liu, Huan and Morstatter, Fred and Goolsby, Rebecca},
      booktitle={Advances in Social Network Analysis and Mining (ASONAM) 2013},
      pages={517--524},
      year={2013}
    }
    `,
    
    'kumar2014twitter': `@misc{kumar2014twitter,
      title={Twitter Data Analytics},
      author={Kumar, Shamanth and Morstatter, Fred and Liu, Huan},
      year={2014},
      publisher={Springer}
    }
    `,
    
    'morstatter2014biased': `@inproceedings{morstatter2014biased,
      title={When is it biased? Assessing the representativeness of twitter's streaming API},
      author={Morstatter, Fred and Pfeffer, Jürgen and Liu, Huan},
      booktitle={Proceedings of the 23rd international conference on world wide web},
      pages={555--556},
      year={2014}
    }
    `,
    
    'kumar2014monitoring': `@article{kumar2014monitoring,
      title={Monitoring Social Media for Humanitarian Assistance and Disaster Relief},
      author={Kumar, Shamanth and Morstatter, Fred and Liu, Huan},
      journal={Special Technical Committee on Social Networking},
      volume={2},
      number={1},
      year={2014},
      publisher={Institute of Electrical and Electronics Engineers}
    }
    `,
    
    'morstatter2014finding': `@inproceedings{morstatter2014finding,
      title={Finding Eyewitness Tweets During Crises},
      author={Morstatter, Fred and Lubold, Nichola and Pon-Barry, Heather and Pfeffer, Jürgen and Liu, Huan},
      booktitle={Proceedings of the ACL 2014 Workshop on Language Technologies and Computational Social Science},
      pages={23--27},
      year={2014},
      organization={Association for Computational Linguistics}
    }
    `,
    
    'kenett2014discovering': `@article{kenett2014discovering,
      title={Discovering Social Events through Online Attention},
      author={Kenett, Dror Y. and Morstatter, Fred and Stanley, H. Eugene and Liu, Huan},
      journal={PLoS ONE},
      volume={9},
      number={7},
      pages={e102001},
      year={2014},
      publisher={Public Library of Science}
    }
    `,
    
    'carley2014embassies': `@article{carley2014embassies,
      title={Embassies burning: toward a near-real-time assessment of social media using geo-temporal dynamic network analytics},
      author={Carley, Kathleen M and Pfeffer, Jürgen and Morstatter, Fred and Liu, Huan},
      journal={Social Network Analysis and Mining},
      volume={4},
      pages={1--23},
      year={2014},
      publisher={Springer Vienna}
    }
    `,
    
    'morstatter2015discovering': `@article{morstatter2015discovering,
      title={Discovering Location Information in Social Media.},
      author={Morstatter, Fred and Gao, Huiji and Liu, Huan},
      journal={IEEE Data Eng. Bull.},
      volume={38},
      number={2},
      pages={4--13},
      year={2015}
    }
    `,
    
    'morstatter2015text': `@article{morstatter2015text,
      title={Text, Topics, and Turkers: A Consensus Measure for Statistical Topics},
      author={Morstatter, Fred and Pfeffer, Jürgen and Mayer, Katja and Liu, Huan},
      journal={Hypertext and Social Media},
      volume={26},
      year={2015},
      publisher={ACM}
    }
    `,
    
    'sampson2015surpassing': `@article{sampson2015surpassing,
      title={Surpassing the Limit: Keyword Clustering to Improve Twitter Sample Coverage},
      author={Sampson, Justin and Morstatter, Fred and Maciejewski, Ross and Liu, Huan},
      year={2015}
    }
    `,
    
    'sampson2015real': `@inproceedings{sampson2015real,
      title={Real-Time Crisis Mapping using Language Distribution},
      author={Sampson, Justin and Morstatter, Fred and Zafarani, Reza and Liu, Huan},
      booktitle={International Conference on Data Mining},
      year={2015}
    }
    `,
    
    'dani2015social': `@inproceedings{dani2015social,
      title={Social Answer: A System for Finding Appropriate Sites for Questions in Social Media},
      author={Dani, Harsh and Morstatter, Fred and Hu, Xia and Yang, Zhen and Liu, Huan},
      booktitle={International Conference on Data Mining},
      year={2015}
    }
    `,
    
    'ranganath2016predicting': `@inproceedings{ranganath2016predicting,
      title={Predicting online protest participation of social media users},
      author={Ranganath, Suhas and Morstatter, Fred and Hu, Xia and Tang, Jiliang and Wang, Suhang and Liu, Huan},
      booktitle={Proceedings of the AAAI Conference on Artificial Intelligence},
      volume={30},
      number={1},
      year={2016}
    }
    `,
    
    'li2017feature': `@article{li2017feature,
      title={Feature selection: A data perspective},
      author={Li, Jundong and Cheng, Kewei and Wang, Suhang and Morstatter, Fred and Trevino, Robert P and Tang, Jiliang and Liu, Huan},
      journal={ACM computing surveys (CSUR)},
      volume={50},
      number={6},
      pages={1--45},
      year={2017},
      publisher={ACM New York, NY, USA}
    }
    `,
    
    'morstatter2016can': `@inproceedings{morstatter2016can,
      title={Can One Tamper with the Sample API? Toward Neutralizing Bias from Spam and Bot Content},
      author={Morstatter, Fred and Dani, Harsh and Sampson, Justin and Liu, Huan},
      booktitle={World Wide Web},
      year={2016}
    }
    `,
    
    'morstatter2016novel': `@inproceedings{morstatter2016novel,
      title={A Novel Measure for Coherence in Statistical Topic Models},
      author={Morstatter, Fred and Liu, Huan},
      booktitle={Association of Computational Linguistics},
      year={2016}
    }
    `,
    
    'wu2016integrating': `@inproceedings{wu2016integrating,
      title={Integrating Interactive and Computational Approaches for News Understanding},
      author={Wu, Liang and Morstatter, Fred and Baird, J Daniel and Sampson, Justin and Shu, Kai and Liu, Huan},
      booktitle={SBP},
      year={2016}
    }
    `,
    
    'morstatter2016new': `@inproceedings{morstatter2016new,
      title={A New Approach to Bot Detection: Striking the Balance Between Precision and Recall},
      author={Morstatter, Fred and Wu, Liang and Nazer, Tahora H and Carley, Kathleen M and Liu, Huan},
      booktitle={ASONAM},
      year={2016}
    }
    `,
    
    'wu2016slangsd': `@article{wu2016slangsd,
      title={Slangsd: Building and using a sentiment dictionary of slang words for short-text sentiment classification},
      author={Wu, Liang and Morstatter, Fred and Liu, Huan},
      journal={arXiv preprint arXiv:1608.05129},
      year={2016}
    }
    `,
    
    'liu2016good': `@article{liu2016good,
      title={The good, the bad, and the ugly: uncovering novel research opportunities in social media mining},
      author={Liu, Huan and Morstatter, Fred and Tang, Jiliang and Zafarani, Reza},
      journal={International Journal of Data Science and Analytics},
      volume={1},
      pages={137--143},
      year={2016},
      publisher={Springer International Publishing}
    }
    `,
    
    'wang2016paired': `@article{wang2016paired,
      title={Paired Restricted Boltzmann Machine for Linked Data},
      author={Wang, Suhang and Tang, Jiliang and Morstatter, Fred and Liu, Huan},
      year={2016}
    }
    `,
    
    'sampson2016leveraging': `@article{sampson2016leveraging,
      title={Leveraging the Implicit Structure within Social Media for Emergent Rumor Detection},
      author={Sampson, Justin and Morstatter, Fred and Wu, Liang and Liu, Huan},
      year={2016}
    }
    `,
    
    'morstatter2016replacing': `@article{morstatter2016replacing,
      title={Replacing mechanical turkers? challenges in the evaluation of models with semantic properties},
      author={Morstatter, Fred and Liu, Huan},
      journal={Journal of Data and Information Quality (JDIQ)},
      volume={7},
      number={4},
      pages={1--4},
      year={2016},
      publisher={ACM New York, NY, USA}
    }
    `,
    
    'morstatter2016detecting': `@inproceedings{morstatter2016detecting,
      title={Detecting and mitigating bias in social media},
      author={Morstatter, Fred},
      booktitle={2016 IEEE/ACM International Conference on Advances in Social Networks Analysis and Mining (ASONAM)},
      pages={1347--1348},
      year={2016},
      organization={IEEE}
    }
    `,
    
    'nazer2016finding': `@inproceedings{nazer2016finding,
      title={Finding requests in social media for disaster relief},
      author={Nazer, Tahora H and Morstatter, Fred and Dani, Harsh and Liu, Huan},
      booktitle={2016 IEEE/ACM International Conference on Advances in Social Networks Analysis and Mining (ASONAM)},
      pages={1410--1413},
      year={2016},
      organization={IEEE}
    }
    `,
    
    'wu2016mining': `@article{wu2016mining,
      title={Mining misinformation in social media},
      author={Wu, Liang and Morstatter, Fred and Hu, Xia and Liu, Huan},
      journal={Big data in complex and social networks},
      pages={123--152},
      year={2016},
      publisher={CRC Press}
    }
    `,
    
    'morstatter2016using': `@inproceedings{morstatter2016using,
      title={Using Biased Social Samples for Disaster Response},
      author={Morstatter, Fred},
      booktitle={2016 IEEE 2nd International Conference on Collaboration and Internet Computing (CIC)},
      pages={456--457},
      year={2016},
      organization={IEEE}
    }
    `,
    
    'wu2017detecting': `@inproceedings{wu2017detecting,
      title={Detecting camouflaged content polluters},
      author={Wu, Liang and Hu, Xia and Morstatter, Fred and Liu, Huan},
      booktitle={Proceedings of the International AAAI Conference on Web and Social Media},
      volume={11},
      number={1},
      pages={696--699},
      year={2017}
    }
    `,
    
    'wu2017adaptive': `@inproceedings{wu2017adaptive,
      title={Adaptive spammer detection with sparse group modeling},
      author={Wu, Liang and Hu, Xia and Morstatter, Fred and Liu, Huan},
      booktitle={Proceedings of the international AAAI conference on web and social media},
      volume={11},
      number={1},
      pages={319--326},
      year={2017}
    }
    `,
    
    'morstatter2017discovering': `@article{morstatter2017discovering,
      title={Discovering, assessing, and mitigating data bias in social media},
      author={Morstatter, Fred and Liu, Huan},
      journal={Online Social Networks and Media},
      volume={1},
      pages={1--13},
      year={2017},
      publisher={Elsevier}
    }
    `,
    
    'nazer2017close': `@inproceedings{nazer2017close,
      title={A Close Look at Tinder Bots},
      author={Nazer, Tahora H and Morstatter, Fred and Tyson, Gareth and Liu, Huan},
      booktitle={SDM},
      year={2017}
    }
    `,
    
    'morstatter2017cross': `@article{morstatter2017cross,
      title={Cross-platform emoji interpretation: analysis, a solution, and applications},
      author={Morstatter, Fred and Shu, Kai and Wang, Suhang and Liu, Huan},
      journal={arXiv preprint arXiv:1709.04969},
      year={2017}
    }
    `,
    
    'kinder2017archiving': `@article{kinder2017archiving,
      title={Archiving information from geotagged tweets to promote reproducibility and comparability in social media research},
      author={Kinder-Kurlanda, Katharina and Weller, Katrin and Zenk-Möltgen, Wolfgang and Pfeffer, Jürgen and Morstatter, Fred},
      journal={Big Data \\& Society},
      volume={4},
      number={2},
      pages={2053951717736336},
      year={2017},
      publisher={SAGE Publications Sage UK: London, England}
    }
    `,
    
    'wu2018toward': `@inproceedings{wu2018toward,
      title={Toward relational learning with misinformation},
      author={Wu, Liang and Li, Jundong and Morstatter, Fred and Liu, Huan},
      booktitle={Proceedings of the 2018 SIAM International Conference on Data Mining},
      pages={711--719},
      year={2018},
      organization={Society for Industrial and Applied Mathematics}
    }
    `,
    
    'morstatter2018alt': `@inproceedings{morstatter2018alt,
      title={From alt-right to alt-rechts: Twitter analysis of the 2017 german federal election},
      author={Morstatter, Fred and Shao, Yunqiu and Galstyan, Aram and Karunasekera, Shanika},
      booktitle={Companion Proceedings of the The Web Conference 2018},
      pages={621--628},
      year={2018}
    }
    `,
    
    'alfifi2018measuring': `@article{alfifi2018measuring,
      title={Measuring the impact of ISIS social media strategy},
      author={Alfifi, Majid and Kaghazgaran, Parisa and Caverlee, James and Morstatter, Fred},
      journal={MIS2. Marina Del Ray, California},
      year={2018}
    }
    `,
    
    'arakelyan2018mining': `@incollection{arakelyan2018mining,
      title={Mining and forecasting career trajectories of music artists},
      author={Arakelyan, Shushan and Morstatter, Fred and Martin, Margaret and Ferrara, Emilio and Galstyan, Aram},
      booktitle={Proceedings of the 29th on Hypertext and Social Media},
      pages={11--19},
      year={2018}
    }
    `,
    
    'wu2018slangsd': `@article{wu2018slangsd,
      title={SlangSD: building, expanding and using a sentiment dictionary of slang words for short-text sentiment classification},
      author={Wu, Liang and Morstatter, Fred and Liu, Huan},
      journal={Language Resources and Evaluation},
      volume={52},
      pages={839--852},
      year={2018},
      publisher={Springer Netherlands}
    }
    `,
    
    'morstatter2018search': `@article{morstatter2018search,
      title={In search of coherence and consensus: measuring the interpretability of statistical topics},
      author={Morstatter, Fred and Liu, Huan},
      journal={Journal of Machine Learning Research},
      volume={18},
      number={169},
      pages={1--32},
      year={2018}
    }
    `,
    
    'morstatter2018identifying': `@article{morstatter2018identifying,
      title={Identifying framing bias in online news},
      author={Morstatter, Fred and Wu, Liang and Yavanoglu, Uraz and Corman, Stephen R and Liu, Huan},
      journal={ACM Transactions on Social Computing},
      volume={1},
      number={2},
      pages={1--18},
      year={2018},
      publisher={ACM New York, NY, USA}
    }
    `,
    
    'helderop2019hidden': `@article{helderop2019hidden,
      title={Hidden in plain sight: A machine learning approach for detecting prostitution activity in Phoenix, Arizona},
      author={Helderop, Edward and Huff, Jessica and Morstatter, Fred and Grubesic, Anthony and Wallace, Danielle},
      journal={Applied spatial analysis and policy},
      volume={12},
      pages={941--963},
      year={2019},
      publisher={Springer Netherlands}
    }
    `,
    
    'pfeffer2018tampering': `@article{pfeffer2018tampering,
      title={Tampering with Twitter’s sample API},
      author={Pfeffer, Jürgen and Mayer, Katja and Morstatter, Fred},
      journal={EPJ Data Science},
      volume={7},
      number={1},
      pages={50},
      year={2018},
      publisher={Springer Berlin Heidelberg}
    }
    `,
    
    'mirtaheri2021identifying': `@article{mirtaheri2021identifying,
      title={Identifying and analyzing cryptocurrency manipulations in social media},
      author={Mirtaheri, Mehrnoosh and Abu-El-Haija, Sami and Morstatter, Fred and Ver Steeg, Greg and Galstyan, Aram},
      journal={IEEE Transactions on Computational Social Systems},
      volume={8},
      number={3},
      pages={607--617},
      year={2021},
      publisher={IEEE}
    }
    `,
    
    'morstatter2017characterizing': `@article{morstatter2017characterizing,
      title={Characterizing and Identifying Shills in Social Media},
      author={Morstatter, Fred and Trevino, Robert P and Liu, Huan},
      journal={SBP},
      year={2017}
    }
    `,
    
    'mehrabi2019debiasing': `@inproceedings{mehrabi2019debiasing,
      title={Debiasing community detection: the importance of lowly connected nodes},
      author={Mehrabi, Ninareh and Morstatter, Fred and Peng, Nanyun and Galstyan, Aram},
      booktitle={Proceedings of the 2019 IEEE/ACM International Conference on Advances in Social Networks Analysis and Mining},
      pages={509--512},
      year={2019}
    }
    `,
    
    'alfifi2019large': `@inproceedings{alfifi2019large,
      title={A large-scale study of ISIS social media strategy: Community size, collective influence, and behavioral impact},
      author={Alfifi, Majid and Kaghazgaran, Parisa and Caverlee, James and Morstatter, Fred},
      booktitle={Proceedings of the international AAAI conference on web and social media},
      volume={13},
      pages={58--67},
      year={2019}
    }
    `,
    
    'mehrabi2021survey': `@article{mehrabi2021survey,
      title={A survey on bias and fairness in machine learning},
      author={Mehrabi, Ninareh and Morstatter, Fred and Saxena, Nripsuta and Lerman, Kristina and Galstyan, Aram},
      journal={ACM computing surveys (CSUR)},
      volume={54},
      number={6},
      pages={1--35},
      year={2021},
      publisher={ACM New York, NY, USA}
    }
    `,
    
    'fred2019sage': `@inproceedings{fred2019sage,
      title={SAGE: A Hybrid Geopolitical Event Forecasting System},
      booktitle={International Joint Conference on Artificial Intelligence},
      volume={2019},
      pages={6557--6559},
      year={2019}
    }
    `,
    
    'wu2019misinformation': `@article{wu2019misinformation,
      title={Misinformation in social media: definition, manipulation, and detection},
      author={Wu, Liang and Morstatter, Fred and Carley, Kathleen M and Liu, Huan},
      journal={ACM SIGKDD explorations newsletter},
      volume={21},
      number={2},
      pages={80--90},
      year={2019},
      publisher={ACM New York, NY, USA}
    }
    `,
    
    'mehrabi2020man': `@inproceedings{mehrabi2020man,
      title={Man is to person as woman is to location: Measuring gender bias in named entity recognition},
      author={Mehrabi, Ninareh and Gowda, Thamme and Morstatter, Fred and Peng, Nanyun and Galstyan, Aram},
      booktitle={Proceedings of the 31st ACM conference on Hypertext and Social Media},
      pages={231--232},
      year={2020}
    }
    `,
    
    'huang2020anchor': `@article{huang2020anchor,
      title={Anchor attention for hybrid crowd forecasts aggregation},
      author={Huang, Yuzhong and Abeliuk, Andrés and Morstatter, Fred and Atanasov, Pavel and Galstyan, Aram},
      journal={arXiv preprint arXiv:2003.12447},
      year={2020}
    }
    `,
    
    'ziems2020aggressive': `@inproceedings{ziems2020aggressive,
      title={Aggressive, repetitive, intentional, visible, and imbalanced: Refining representations for cyberbullying classification},
      author={Ziems, Caleb and Vigfusson, Ymir and Morstatter, Fred},
      booktitle={Proceedings of the International AAAI Conference on Web and Social Media},
      volume={14},
      pages={808--819},
      year={2020}
    }
    `,
    
    'huang2020graph': `@inproceedings{huang2020graph,
      title={Graph embedding with personalized context distribution},
      author={Huang, Di and He, Zihao and Huang, Yuzhong and Sun, Kexuan and Abu-El-Haija, Sami and Perozzi, Bryan and Lerman, Kristina and Morstatter, Fred and Galstyan, Aram},
      booktitle={Companion Proceedings of the Web Conference 2020},
      pages={655--661},
      year={2020}
    }
    `,
    
    'mehrabi2020statistical': `@article{mehrabi2020statistical,
      title={Statistical equity: A fairness classification objective},
      author={Mehrabi, Ninareh and Huang, Yuzhong and Morstatter, Fred},
      journal={arXiv preprint arXiv:2005.07293},
      year={2020}
    }
    `,
    
    'matsui2020leveraging': `@article{matsui2020leveraging,
      title={Leveraging Clickstream Trajectories to Reveal Low-Quality Workers in Crowdsourced Forecasting Platforms},
      author={Matsui, Akira and Ferrara, Emilio and Morstatter, Fred and Abeliuk, Andrés and Galstyan, Aram},
      journal={arXiv preprint arXiv:2009.01966},
      year={2020}
    }
    `,
    
    'abeliuk2020quantifying': `@article{abeliuk2020quantifying,
      title={Quantifying machine influence over human forecasters},
      author={Abeliuk, Andrés and Benjamin, Daniel M and Morstatter, Fred and Galstyan, Aram},
      journal={Scientific reports},
      volume={10},
      number={1},
      pages={1--14},
      year={2020},
      publisher={Nature Publishing Group}
    }
    `,
    
    'mirtaheri2020one': `@article{mirtaheri2020one,
      title={One-shot learning for temporal knowledge graphs},
      author={Mirtaheri, Mehrnoosh and Rostami, Mohammad and Ren, Xiang and Morstatter, Fred and Galstyan, Aram},
      journal={arXiv preprint arXiv:2010.12144},
      year={2020}
    }
    `,
    
    'mehrabi2021exacerbating': `@inproceedings{mehrabi2021exacerbating,
      title={Exacerbating algorithmic bias through fairness attacks},
      author={Mehrabi, Ninareh and Naveed, Muhammad and Morstatter, Fred and Galstyan, Aram},
      booktitle={Proceedings of the AAAI Conference on Artificial Intelligence},
      volume={35},
      number={10},
      pages={8930--8938},
      year={2021}
    }
    `,
    
    'sethi2021models': `@article{sethi2021models,
      title={Models, Markets, and the Forecasting of Elections},
      author={Sethi, Rajiv and Seager, Julie and Cai, Emily and Benjamin, Daniel M and Morstatter, Fred},
      journal={arXiv preprint arXiv:2102.04936},
      year={2021}
    }
    `,
    
    'kaghazgaran2021organizational': `@article{kaghazgaran2021organizational,
      title={Organizational Artifacts of Code Development},
      author={Kaghazgaran, Parisa and Lubold, Nichola and Morstatter, Fred},
      journal={arXiv preprint arXiv:2105.14637},
      year={2021}
    }
    `,
    
    'mehrabi2021lawyers': `@article{mehrabi2021lawyers,
      title={Lawyers are dishonest? quantifying representational harms in commonsense knowledge resources},
      author={Mehrabi, Ninareh and Zhou, Pei and Morstatter, Fred and Pujara, Jay and Ren, Xiang and Galstyan, Aram},
      journal={arXiv preprint arXiv:2103.11320},
      year={2021}
    }
    `,
    
    'priniski2021mapping': `@article{priniski2021mapping,
      title={Mapping moral valence of tweets following the killing of George Floyd},
      author={Priniski, J Hunter and Mokhberian, Negar and Harandizadeh, Bahareh and Morstatter, Fred and Lerman, Kristina and Lu, Hongjing and Brantingham, P Jeffrey},
      journal={arXiv preprint arXiv:2104.09578},
      year={2021}
    }
    `,
    
    'alipourfard2021systematizing': `@article{alipourfard2021systematizing,
      title={Systematizing confidence in open research and evidence (score)},
      author={Alipourfard, Nazanin and Arendt, Beatrix and Benjamin, Daniel M and Benkler, Noam and Bishop, Michael and Burstein, Mark and Bush, Martin and Caverlee, James and Chen, Yiling and Clark, Chae and others},
      journal={SocArXiv},
      year={2021}
    }
    `,
    
    'tian2021identifying': `@inproceedings{tian2021identifying,
      title={Identifying distributional perspectives from colingual groups},
      author={Tian, Yufei and Chakrabarty, Tuhin and Morstatter, Fred and Peng, Nanyun},
      booktitle={Proceedings of the Ninth International Workshop on Natural Language Processing for Social Media},
      pages={178--190},
      year={2021}
    }
    `,
    
    'nghiem2021detecting': `@article{nghiem2021detecting,
      title={Detecting cryptocurrency pump-and-dump frauds using market and social signals},
      author={Nghiem, Huy and Muric, Goran and Morstatter, Fred and Ferrara, Emilio},
      journal={Expert Systems with Applications},
      volume={182},
      pages={115284},
      year={2021},
      publisher={Pergamon}
    }
    `,
    
    'rao2021political': `@article{rao2021political,
      title={Political partisanship and antiscience attitudes in online discussions about COVID-19: Twitter content analysis},
      author={Rao, Ashwin and Morstatter, Fred and Hu, Minda and Chen, Emily and Burghardt, Keith and Ferrara, Emilio and Lerman, Kristina},
      journal={Journal of medical Internet research},
      volume={23},
      number={6},
      pages={e26692},
      year={2021},
      publisher={JMIR Publications Toronto, Canada}
    }
    `,
    
    'wu2016misinformation': `@article{wu2016misinformation,
      title={Misinformation in Social Media: Diffusion, Detection and Intervention},
      author={Wu, Liang and Morstatter, Fred and Liu, Huan},
      journal={SBP-BRiMS, June},
      year={2016}
    }
    `,
    
    'jin2020forecastqa': `@article{jin2020forecastqa,
      title={Forecastqa: a question answering challenge for event forecasting with temporal text data},
      author={Jin, Woojeong and Khanna, Rahul and Kim, Suji and Lee, Dong-Ho and Morstatter, Fred and Galstyan, Aram and Ren, Xiang},
      journal={arXiv preprint arXiv:2005.00792},
      year={2020}
    }
    `,
    
    'morstatter2021ll': `@article{morstatter2021ll,
      title={I'll Play on My Other Account: The Network and Behavioral Differences of Sybils},
      author={Morstatter, Fred and Kim, Do Own and Jonckheere, Natalie and Liu, Calvin and Seth, Malika and Williams, Dmitri},
      journal={Proceedings of the ACM on Human-Computer Interaction},
      volume={5},
      number={CHI PLAY},
      pages={1--18},
      year={2021},
      publisher={ACM New York, NY, USA}
    }
    `,
    
    'khaund2021social': `@article{khaund2021social,
      title={Social bots and their coordination during online campaigns: A survey},
      author={Khaund, Tuja and Kirdemir, Baris and Agarwal, Nitin and Liu, Huan and Morstatter, Fred},
      journal={IEEE Transactions on Computational Social Systems},
      volume={9},
      number={2},
      pages={530--545},
      year={2021},
      publisher={IEEE}
    }
    `,
    
    'mehrabi2021attributing': `@article{mehrabi2021attributing,
      title={Attributing fair decisions with attention interventions},
      author={Mehrabi, Ninareh and Gupta, Umang and Morstatter, Fred and Steeg, Greg Ver and Galstyan, Aram},
      journal={arXiv preprint arXiv:2109.03952},
      year={2021}
    }
    `,
    
    'lee2021autotrigger': `@article{lee2021autotrigger,
      title={Autotrigger: Named entity recognition with auxiliary trigger extraction},
      author={Lee, Dong-Ho and Selvam, Ravi Kiran and Sarwar, Sheikh Muhammad and Lin, Bill Yuchen and Agarwal, Mahak and Morstatter, Fred and Pujara, Jay and Boschee, Elizabeth and Allan, James and Ren, Xiang},
      journal={arXiv preprint arXiv:2109.04726},
      year={2021}
    }
    `,
    
    'mirtaheri2019tensor': `@inproceedings{mirtaheri2019tensor,
      title={Tensor-based Method for Temporal Geopolitical Event Forecasting},
      author={Mirtaheri, Mehrnoosh and Abu-El-Haija, Sami and Hossain, Tozammel and Morstatter, Fred and Galstyan, Aram},
      booktitle={ICML Workshop on Learning and Reasoning with Graph-Structured Representations, Long Beach, CA},
      year={2019}
    }
    `,
    
    'harandizadeh2022keyword': `@inproceedings{harandizadeh2022keyword,
      title={Keyword assisted embedded topic model},
      author={Harandizadeh, Bahareh and Priniski, J Hunter and Morstatter, Fred},
      booktitle={Proceedings of the Fifteenth ACM International Conference on Web Search and Data Mining},
      pages={372--380},
      year={2022}
    }
    `,
    
    'nghiem2021stop': `@article{nghiem2021stop,
      title={" Stop Asian hate!": refining detection of anti-Asian hate speech during the covid-19 pandemic},
      author={Nghiem, Huy and Morstatter, Fred},
      journal={arXiv preprint arXiv:2112.02265},
      year={2021}
    }
    `,
    
    'shaik2021analyzing': `@inproceedings{shaik2021analyzing,
      title={Analyzing race and citizenship bias in Wikidata},
      author={Shaik, Zaina and Ilievski, Filip and Morstatter, Fred},
      booktitle={2021 IEEE 18th international conference on mobile Ad Hoc and smart systems (MASS)},
      pages={665--666},
      year={2021},
      organization={IEEE}
    }
    `,
    
    'rao2022partisan': `@article{rao2022partisan,
      title={Partisan asymmetries in exposure to misinformation},
      author={Rao, Ashwin and Morstatter, Fred and Lerman, Kristina},
      journal={Scientific Reports},
      volume={12},
      number={1},
      pages={15671},
      year={2022},
      publisher={Nature Publishing Group UK London}
    }
    `,
    
    'mehrabi2022robust': `@article{mehrabi2022robust,
      title={Robust conversational agents against imperceptible toxicity triggers},
      author={Mehrabi, Ninareh and Beirami, Ahmad and Morstatter, Fred and Galstyan, Aram},
      journal={arXiv preprint arXiv:2205.02392},
      year={2022}
    }
    `,
    
    'melotte2022does': `@article{melotte2022does,
      title={Where Does Bias in Common Sense Knowledge Models Come From?},
      author={Melotte, Sara and Ilievski, Filip and Zhang, Linglan and Malte, Aditya and Mutha, Namita and Morstatter, Fred and Mehrabi, Ninareh},
      journal={IEEE Internet Computing},
      volume={26},
      number={4},
      pages={12--20},
      year={2022},
      publisher={IEEE}
    }
    `,
    
    'sun2022assessing': `@inproceedings{sun2022assessing,
      title={Assessing Scientific Research Papers with Knowledge Graphs},
      author={Sun, Kexuan and Qiu, Zhiqiang and Salinas, Abel and Huang, Yuzhong and Lee, Dong-Ho and Benjamin, Daniel and Morstatter, Fred and Ren, Xiang and Lerman, Kristina and Pujara, Jay},
      booktitle={Proceedings of the 45th International ACM SIGIR Conference on Research and Development in Information Retrieval},
      pages={2467--2472},
      year={2022}
    }
    `,
    
    'lerman2022gendered': `@article{lerman2022gendered,
      title={Gendered citation patterns among the scientific elite},
      author={Lerman, Kristina and Yu, Yulin and Morstatter, Fred and Pujara, Jay},
      journal={Proceedings of the National Academy of Sciences},
      volume={119},
      number={40},
      pages={e2206070119},
      year={2022},
      publisher={National Academy of Sciences}
    }
    `,
    
    'binh2022samba': `@inproceedings{binh2022samba,
      title={Samba: Identifying Inappropriate Videos for Young Children on YouTube},
      author={Binh, Le and Tandon, Rajat and Oinar, Chingis and Liu, Jeffrey and Durairaj, Uma and Guo, Jiani and Zahabizadeh, Spencer and Ilango, Sanjana and Tang, Jeremy and Morstatter, Fred and others},
      booktitle={Proceedings of the 31st ACM International Conference on Information \\& Knowledge Management},
      pages={88--97},
      year={2022}
    }
    `,
    
    'mokhberian2022noise': `@inproceedings{mokhberian2022noise,
      title={Noise Audits Improve Moral Foundation Classification},
      author={Mokhberian, Negar and Hopp, Frederic R and Harandizadeh, Bahareh and Morstatter, Fred and Lerman, Kristina},
      booktitle={2022 IEEE/ACM International Conference on Advances in Social Networks Analysis and Mining (ASONAM)},
      pages={147--154},
      year={2022},
      organization={IEEE}
    }
    `,
    
    'rao2022retweets': `@article{rao2022retweets,
      title={Retweets Distort Exposure to Polarized Information},
      author={Rao, Ashwin and Morstatter, Fred and Lerman, Kristina},
      journal={arXiv preprint arXiv:2211.16480},
      year={2022}
    }
    `,
    
    'rao2020political': `@article{rao2020political,
      title={Political partisanship and anti-science attitudes in online discussions about covid-19},
      author={Rao, Ashwin and Morstatter, Fred and Hu, Minda and Chen, Emily and Burghardt, Keith and Ferrara, Emilio and Lerman, Kristina},
      journal={arXiv preprint arXiv:2011.08498},
      year={2020}
    }
    `,
    
    'pfeffer2016geotagged': `@article{pfeffer2016geotagged,
      title={Geotagged twitter posts from the united states: A tweet collection to investigate representativeness},
      author={Pfeffer, J and Morstatter, F},
      journal={Version: 1. GESIS Data Archive. Dataset. http://doi. org/10.7802},
      volume={1166},
      year={2016}
    }
    `,
    
    'dorn2023gender': `@article{dorn2023gender,
      title={Gender and Prestige Bias in Coronavirus News Reporting},
      author={Dorn, Rebecca and Ma, Yiwen and Morstatter, Fred and Lerman, Kristina},
      journal={arXiv preprint arXiv:2301.11994},
      year={2023}
    }
    `,
    
    'pfeffer2023just': `@inproceedings{pfeffer2023just,
      title={Just another day on Twitter: a complete 24 hours of Twitter data},
      author={Pfeffer, Juergen and Matter, Daniel and Jaidka, Kokil and Varol, Onur and Mashhadi, Afra and Lasser, Jana and Assenmacher, Dennis and Wu, Siqi and Yang, Diyi and Brantner, Cornelia and others},
      booktitle={Proceedings of the International AAAI Conference on Web and Social Media},
      volume={17},
      pages={1073--1081},
      year={2023}
    }
    `,
    
    'nuti2022productive': `@inproceedings{nuti2022productive,
      title={Productive Online Discourse for Emergency Response},
      author={Nuti, Gaurav and Penafiel, Louis and Ward, Janelle and Salinas, Abel and Morstatter, Fred and Schurr, Nathan and McCormack, R},
      booktitle={Workshop Proceedings of the 16th International AAAI Conference on Web and Social Media. Retrieved from https://doi. org/10.36190},
      year={2022}
    }
    `,
    
    'benjamin2023hybrid': `@article{benjamin2023hybrid,
      title={Hybrid forecasting of geopolitical events},
      author={Benjamin, Daniel M and Morstatter, Fred and Abbas, Ali E and Abeliuk, Andres and Atanasov, Pavel and Bennett, Stephen and Beger, Andreas and Birari, Saurabh and Budescu, David V and Catasta, Michele and others},
      journal={AI Magazine},
      year={2023}
    }
    `,
    
    'lee2023autotrigger': `@inproceedings{lee2023autotrigger,
      title={AutoTriggER: Label-Efficient and Robust Named Entity Recognition with Auxiliary Trigger Extraction},
      author={Lee, Dong-Ho and Selvam, Ravi Kiran and Sarwar, Sheikh Muhammad and Lin, Bill Yuchen and Morstatter, Fred and Pujara, Jay and Boschee, Elizabeth and Allan, James and Ren, Xiang},
      booktitle={Proceedings of the 17th Conference of the European Chapter of the Association for Computational Linguistics},
      pages={3003--3017},
      year={2023}
    }
    `,
    
    'lee2023temporal': `@article{lee2023temporal,
      title={Temporal Knowledge Graph Forecasting Without Knowledge Using In-Context Learning},
      author={Lee, Dong-Ho and Ahrabian, Kian and Jin, Woojeong and Morstatter, Fred and Pujara, Jay},
      journal={arXiv preprint arXiv:2305.10613},
      year={2023}
    }
    `,
    
    'deshpande2023contextualizing': `@article{deshpande2023contextualizing,
      title={Contextualizing Argument Quality Assessment with Relevant Knowledge},
      author={Deshpande, Darshan and Sourati, Zhivar and Ilievski, Filip and Morstatter, Fred},
      journal={arXiv preprint arXiv:2305.12280},
      year={2023}
    }
    `,
    
    'rao2023pandemic': `@article{rao2023pandemic,
      title={Pandemic Culture Wars: Partisan Asymmetries in the Moral Language of COVID-19 Discussions},
      author={Rao, Ashwin and Guo, Siyi and Wang, Sze-Yuh Nina and Morstatter, Fred and Lerman, Kristina},
      journal={arXiv preprint arXiv:2305.18533},
      year={2023}
    }
    `,
    
    'shaikh2023modeling': `@article{shaikh2023modeling,
      title={Modeling Cross-Cultural Pragmatic Inference with Codenames Duet},
      author={Shaikh, Omar and Ziems, Caleb and Held, William and Pariani, Aryan J and Morstatter, Fred and Yang, Diyi},
      journal={arXiv preprint arXiv:2306.02475},
      year={2023}
    }
    `,
    
    'marmarelis2023tighter': `@article{marmarelis2023tighter,
      title={Tighter Prediction Intervals for Causal Outcomes Under Hidden Confounding},
      author={Marmarelis, Myrl G and Steeg, Greg Ver and Galstyan, Aram and Morstatter, Fred},
      journal={arXiv preprint arXiv:2306.09520},
      year={2023}
    }
    `,
    
    'sethi2022models': `@article{sethi2022models,
      title={Models, Markets, and Prediction Performance},
      author={Sethi, Rajiv and Seager, Julie and Cai, Emily and Benjamin, Daniel M and Morstatter, Fred and Bobrownicki, Olivia},
      journal={Available at SSRN 3767544},
      year={2022}
    }
    `,
    
    'priniski2023pipeline': `@inproceedings{priniski2023pipeline,
      title={Pipeline for modeling causal beliefs from natural language},
      author={Priniski, John and Verma, Ishaan and Morstatter, Fred},
      booktitle={Proceedings of the 61st Annual Meeting of the Association for Computational Linguistics (Volume 3: System Demonstrations)},
      pages={436--443},
      year={2023}
    }
    `,
    
    'guo2023measuring': `@article{guo2023measuring,
      title={Measuring Online Emotional Reactions to Offline Events},
      author={Guo, Siyi and He, Zihao and Rao, Ashwin and Jang, Eugene and Nan, Yuanfeixue and Morstatter, Fred and Brantingham, Jeffrey and Lerman, Kristina},
      journal={arXiv preprint arXiv:2307.10245},
      year={2023}
    }
    `,
    
    'salinas2023unequal': `@inproceedings{salinas2023unequal,
      title={The Unequal Opportunities of Large Language Models: Examining Demographic Biases in Job Recommendations by ChatGPT and LLaMA},
      author={Salinas, Abel and Shah, Parth and Huang, Yuzhong and McCormack, Robert and Morstatter, Fred},
      booktitle={Proceedings of the 3rd ACM Conference on Equity and Access in Algorithms, Mechanisms, and Optimization},
      pages={1--15},
      year={2023}
    }
    `,
    
    'salinas2023not': `@article{salinas2023not,
      title={" Im not Racist but...": Discovering Bias in the Internal Knowledge of Large Language Models},
      author={Salinas, Abel and Penafiel, Louis and McCormack, Robert and Morstatter, Fred},
      journal={arXiv preprint arXiv:2310.08780},
      year={2023}
    }
    `,
    
    'mokhberian2023capturing': `@article{mokhberian2023capturing,
      title={Capturing Perspectives of Crowdsourced Annotators in Subjective Learning Tasks},
      author={Mokhberian, Negar and Marmarelis, Myrl G and Hopp, Frederic R and Basile, Valerio and Morstatter, Fred and Lerman, Kristina},
      journal={arXiv preprint arXiv:2311.09743},
      year={2023}
    }
    `
    }