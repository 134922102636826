import React from 'react'
import { Card, CardContent, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'


import { articles } from '../content/news'


const styles = theme => ({
  news: {
    margin: '5vw',
    width: '90vw',
    padding: theme.spacing(3, 0),
    '@media (min-width:1000px)': {
      padding: theme.spacing(4, 0),
      width:'90vw',
    },
    '@media (min-width:1200px)': {
      padding: theme.spacing(5, 0),
      width: '80vw',
    },
    '@media (min-width:1400px)': {
      padding: theme.spacing(5, 0),
      width: '70vw',
    },
    '@media (min-width:1600px)': {
      padding: theme.spacing(5, 0),
      width: '60vw',
    },
    '@media (min-width:1750px)': {
      padding: theme.spacing(5, 0),
      width: '50vw',
    },
  },
  title: {
    color: 'white',
    margin: theme.spacing(3, 0, 10, 0),
  },
  article: {
    margin: theme.spacing(10, 0),
  },
  cover: {
    position: 'relative',
    width: '100%',
    height: '15vw',
    marginRight: theme.spacing(3),
    '@media (min-width:1200px)': {
      float: 'left',
      width: '10vw',
      height: '60vh',
    },
    '& h4': {
      position: 'absolute',
      bottom: theme.spacing(3),
      left: 0,
      color: 'whitesmoke',
      fontWeight: '900',
      background: '#111',
      padding: theme.spacing(2),
    },
  },
  content: {
    '& h6': {
      color: '#111',
      marginBottom: theme.spacing(1),
    },
    '& p': {
      color: '#111',
      marginBottom: '1em',
    },
    '& img': {
      width: '100%',
    },
  },
})


class News extends React.Component {

  renderArticleBody(body, i) {
    if ( body.type === 'image' ) {
      return (
        <Typography component="p" variant="body1" key={i}>
          <img src={body.content} alt={body.caption} />
          {body.caption}
        </Typography>
      )
    } else if ( body.type === 'iframe' ) {
      return (
        <iframe
          title='iframe'
          src={body.content}
          width='1000'
          height='700'
          frameBorder='0'
        />
      )
    } else {
      return (
        <Typography component="p" variant="body1" key={i}>
          {body.content}
        </Typography>
      )
    }
  }

  renderArticles() {
    const {classes} = this.props
    return articles.map(article => (
      <Card className={classes.article} key={article.title}>
        <CardContent className={classes.content}>
          <Typography component="h6" variant="h6">
            <b>{article.date}</b>
          </Typography>
          <Typography component="h6" variant="h6">
            <b>{article.authors}</b>
          </Typography>
          <Typography component="h5" variant="h5">
            <b>{article.title}</b>
          </Typography>
          <Typography component="h6" variant="h6">
            <b>{article.subtitle}</b>
          </Typography>
          {article.body.map((b, i) => this.renderArticleBody(b, i))}
          {!!article.publication && (
          <Typography component="h6" variant="h6">
            <b>Publication: <a href={article.publication.link} target="blank">{article.publication.title}</a></b>
          </Typography>
          )}
          {!!article.data && (
          <Typography component="h6" variant="h6">
            <b>Data: <a href={article.data.link} target="blank">{article.data.title}</a></b>
          </Typography>
          )}
        </CardContent>
      </Card>
    ))
  }

  render() {
    const {classes} = this.props
    return (
      <div id="news" className={classes.news}>
        <Typography
          component="h4"
          variant="h4"
          className={classes.title}>
          Bio
        </Typography>
        {this.renderArticles()}
      </div>
    )
  }
}


export default withStyles(styles)(News)
