import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Paper, Typography } from '@material-ui/core'
import Publication from './Publication'
import Input from './Input'

import { parseBibFile, normalizeFieldValue } from 'bibtex'
import { publications } from '../content/publications-fred'

const bibFile = parseBibFile(Object.values(publications).join(' '))


const styles = theme => ({
  publications: {
    margin: '5vw',
    width: '90vw',
    padding: theme.spacing(3),
    '@media (min-width:1000px)': {
      padding: theme.spacing(4),
      width: '90vw',
    },
    '@media (min-width:1200px)': {
      padding: theme.spacing(5),
      width: '80vw',
    },
    '@media (min-width:1400px)': {
      padding: theme.spacing(5),
      width: '70vw',
    },
    '@media (min-width:1600px)': {
      padding: theme.spacing(5),
      width: '60vw',
    },
    '@media (min-width:1750px)': {
      padding: theme.spacing(5),
      width: '50vw',
    },
  },
  title: {
    color: '#111',
    margin: theme.spacing(3, 0, 5, 0),
  },
  wrapper: {
    maxHeight: '50vh',
    overflow: 'auto',
    marginTop: theme.spacing(3),
  },
})


class Publications extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      search: '',
    }
  }

  handleUpdate(search) {
    this.setState({search})
  }

  getAuthors(entry) {
    let authors = entry.getField("author")
    if ( !!authors ) {
      return authors.authors$.map((author, i) => (
        author.firstNames.concat(author.vons).concat(author.lastNames).concat(author.jrs).join(' ') + `${(i < authors.authors$.length - 1) ? ', ' : ''}`
      ))
    }
    return ''
  }

  normalizeValue(entry, field) {
    return (
      normalizeFieldValue(entry.getField(field)) || ''
    ).toString().toLowerCase()
  }

  renderPublications() {
    const {search} = this.state
    const s = search.toLowerCase()

    // build up a dictionary that contains the year that the paper was published.
    const keys = Object.keys(bibFile.entries$)
    var years = {};
    for(var i = 0; i < keys.length; i++){
      const key = keys[i];
      const entry = bibFile.getEntry(key);
      years[key] = this.normalizeValue(entry, 'year')
    }
    
    // sort the keys in *reverse* chronological order.
    // compare the years of the keys, and flip the result
    keys.sort((a, b) => years[b].localeCompare(years[a]))

    return keys.filter(key => {
      const entry = bibFile.getEntry(key)
      const year = this.normalizeValue(entry, 'year')
      const title = this.normalizeValue(entry, 'title')
      const journal = this.normalizeValue(entry, 'journal')
      const booktitle = this.normalizeValue(entry, 'booktitle')
      const authors = this.getAuthors(entry).toString().toLowerCase()
      return year.includes(s) || title.includes(s) || journal.includes(s) || booktitle.includes(s)|| authors.includes(s)
    }).map(key => {
      const entry = bibFile.getEntry(key)
      const data = {
        year: normalizeFieldValue(entry.getField('year')),
        title: normalizeFieldValue(entry.getField('title')),
        journal: normalizeFieldValue(entry.getField('journal')),
        booktitle: normalizeFieldValue(entry.getField('booktitle')),
        authors: this.getAuthors(entry),
        url: normalizeFieldValue(entry.getField('url')),
        type: entry.type.charAt(0).toUpperCase() + entry.type.slice(1),
        bibtex: publications[key.toLowerCase()],
      }
      return (
        <Publication key={key} data={data} />
      )
    })
  }

  render() {
    const {classes} = this.props
    const {search} = this.state
    return (
      <div id="publications" style={{paddingTop: '3vh'}}>
        <Paper elevation={3} className={classes.publications}>
          <Typography
            component="h4"
            variant="h4"
            className={classes.title}>
            Publications
          </Typography>
          <Typography>
            Also available at <a rel="opener" href="https://scholar.google.com/citations?user=u-8h3HcAAAAJ&hl=en">Google Scholar</a>
          </Typography>
          <Input text={search} label={'search publications'} updateText={this.handleUpdate.bind(this)} />
          <div className={classes.wrapper}>
            {this.renderPublications()}
          </div>
        </Paper>
      </div>
    )
  }
}


export default withStyles(styles)(Publications)
